import { MailFilled } from "@ant-design/icons";
import { DeleteFilledIcon, VectorRightIcon } from "@assets/icons";
import {
  IInputPhoneProps,
  IInputWithDropdownProps,
  IKPinProps,
  KAnchor,
  KButton,
  KCardTypeTag,
  KDatePicker,
  KInput,
  KInputBorderless,
  KInputLink,
  KInputPhone,
  KInputWithDropdownFormField,
  KLgText700Secondary88,
  KMdText,
  KMdTextSecondary65,
  KModalAddCard,
  KPin,
  KSelect,
  KSwitch,
  KTextEditor,
  KTextEditorProps,
  KTextarea,
  KTimePicker,
} from "@components";
import { KContext } from "@context";
import { getListAddToCardService } from "@services";
import { useDetailCard } from "@store";
import {
  Color,
  ECardType,
  EDateTimeFormat,
  EmailRegex,
  ICardTagRes,
} from "@types";
import { formatPhoneNumber } from "@utils/formatPhoneNumber";
import { formatDate, getFieldDetail, getInfoCard } from "@utils/index";
import {
  AutoComplete,
  AutoCompleteProps,
  Button,
  CheckboxProps,
  Flex,
  FlexProps,
  InputNumber,
  InputNumberProps,
  InputProps,
  SwitchProps,
} from "antd";
import { DefaultOptionType } from "antd/es/cascader";
import { TextAreaProps } from "antd/es/input";
import { KDatePickerProps } from "components/DatePicker/types";
import { KTextAreaProps } from "components/Input/types";
import { KSelectProps } from "components/Select/type";
import moment from "moment";
import {
  CSSProperties,
  ReactNode,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { Controller, useFormContext } from "react-hook-form";
import { FormItemProps } from "react-hook-form-antd";
import { useParams } from "react-router-dom";
import {
  FormItemBorderless,
  FormItemStyled,
  FormItemSwitch,
  StyledDeleteIconContainer,
} from "./styled-component";

interface KHookForm extends Omit<FormItemProps, "control" | "children"> {
  children?: ReactNode;
}

interface IOptionsLink extends Omit<AutoCompleteProps, "options"> {
  options?: ICardTagRes[];
  cardType: ECardType;
  label: ReactNode;
  name?: string;
  displayName?: string;
}

interface ISwitchForm extends SwitchProps {
  label?: ReactNode;
  name: string;
  styleForm?: CSSProperties;
  title?: string;
  icon?: ReactNode;
}

interface IInputBorderless extends TextAreaProps {
  options?: DefaultOptionType[];
  isAutocomplete?: boolean;
}

export interface ICheckboxForm extends CheckboxProps {
  label?: ReactNode;
  name: string;
  styleForm?: CSSProperties;
  title?: string;
  icon?: ReactNode;
  missed?: boolean;
}

interface IKFormGroupNoControlProps extends FlexProps {
  label?: ReactNode;
}

interface IKFormGroupModalProps extends FlexProps {
  label?: ReactNode;
  suffix?: string;
  required?: boolean;
}

export const KFormGroupNoControl = ({
  label,
  children,
  ...props
}: IKFormGroupNoControlProps) => (
  <Flex flex={1} justify="space-between" gap={8} vertical {...props}>
    {label && <KMdText>{label}</KMdText>}
    {children}
  </Flex>
);

export const KFormGroupModal = ({
  label,
  children,
  suffix,
  required ,
  ...props
}: IKFormGroupModalProps) => (
  <Flex flex={1} justify="space-between" gap={8} vertical {...props}>
    {label && (
      <KMdText>
        {label} {required && <span style={{ color: Color.ErrorHover }}>*</span>}
      </KMdText>
    )}
    {children}
  </Flex>
);

export const KInputMultiple = ({
  content,
  name,
  placeholder,
  disabled,
}: InputProps & KHookForm) => {
  const { watch, setValue, getValues } = useFormContext();
  const inputValue = watch(name);

  const handleRemoveInput = useCallback(
    (index: number) => {
      const value = getValues(name);
      delete value[index];
      setValue(name, value, { shouldDirty: true, shouldValidate: true });
    },
    [inputValue]
  );

  const handleAddInput = useCallback(() => {
    const currentValues = getValues(name) || [];

    if (
      currentValues.length > 0 &&
      currentValues.every((value: string) => value === "")
    ) {
      return;
    }

    if (currentValues.length > 0 && currentValues.includes("")) {
      return;
    }

    setValue(name, [...currentValues, ""], { shouldDirty: false });
  }, [inputValue]);

  const values = useMemo(() => watch(name), [inputValue]);

  return (
    <Flex vertical gap={8}>
      {values?.map((_: any, index: number) => (
        <Flex key={index} gap={10} justify="center">
          <KFormUser.TextArea
            name={`${name}[${index}]`}
            placeholder={placeholder}
            disabled={disabled}
            rows={1}
            autoSize={{ minRows: 1 }}
          />
          <StyledDeleteIconContainer onClick={() => handleRemoveInput(index)}>
            <DeleteFilledIcon/>
          </StyledDeleteIconContainer>
        </Flex>
      ))}
      <KButton
        style={{ width: "100%" }}
        ghost
        title={content}
        size="middle"
        onClick={handleAddInput}
      />
    </Flex>
  );
};

export const KFormGroupUser = ({
  label,
  children,
  name,
  ...props
}: KHookForm) => {
  const { control } = useFormContext();
  return (
    <FormItemStyled
      {...props}
      {...(label && { label: <KMdText>{label}</KMdText> })}
      labelCol={{ span: 24 }}
      labelAlign="left"
      colon={false}
      name={name}
      control={control}
    >
      {children}
    </FormItemStyled>
  );
};

export const KFormUser = {
  InputLocation: ({
    label,
    disabled = false,
    name,
    content,
  }: InputNumberProps & KHookForm) => (
    <KFormGroupUser name={name} label={label}>
      <KInputMultiple
        name={name}
        label={label}
        content={content}
        disabled={disabled}
      ></KInputMultiple>
    </KFormGroupUser>
  ),
  Input: ({
    label,
    name,
    disabled = false,
    ...props
  }: InputProps & KHookForm) => (
    <KFormGroupUser label={label} name={name}>
      <KInput {...props} />
    </KFormGroupUser>
  ),
  InputNumber: ({
    label,
    name,
    disabled = false,
    ...props
  }: InputNumberProps & KHookForm) => (
    <KFormGroupUser label={label} name={name}>
      <InputNumber type="number" style={{ width: "100%" }} {...props} />
    </KFormGroupUser>
  ),
  Pin: ({ label, disabled = false, ...props }: IKPinProps) => (
    <KFormGroupNoControl label={label}>
      <KPin {...props} />
    </KFormGroupNoControl>
  ),
  InputBorderless: ({
    label,
    name,
    size,
    isAutocomplete,
    options,
    ...props
  }: IInputBorderless & KHookForm) => {
    const { control } = useFormContext();
    const onKeyDown = (e?: any) => {
      if (e.key === "Enter") e.preventDefault();
    };

    return (
      <FormItemBorderless
        colon={false}
        label={label}
        control={control}
        name={name}
      >
        {isAutocomplete ? (
          <AutoComplete options={options}>
            <KInputBorderless
              {...props}
              size={size}
              autoSize
              onKeyDown={onKeyDown}
            />
          </AutoComplete>
        ) : (
          <KInputBorderless
            {...props}
            size={size}
            autoSize
            onKeyDown={onKeyDown}
          />
        )}
      </FormItemBorderless>
    );
  },
  InputLink: ({
    label,
    name,
    disabled = false,
    ...props
  }: InputProps & KHookForm) => (
    <KFormGroupUser label={label} name={name}>
      <KInputLink {...props} />
    </KFormGroupUser>
  ),
  InputEmail: ({
    label,
    name,
    disabled = false,
    ...props
  }: InputProps & KHookForm) => {
    const { watch } = useFormContext();
    const handleMailto = () => {
      if (!EmailRegex.test(watch(name))) return;
      window.location.href = `mailto:${watch(name)}`;
    };

    return (
      <KFormGroupUser label={label} name={name}>
        <KInput
          {...props}
          suffix={
            <MailFilled
              style={{ color: Color.PrimaryBase }}
              onClick={handleMailto}
            />
          }
        />
      </KFormGroupUser>
    );
  },
  InputPhone: ({
    label,
    name,
    disabled = false,
    ...props
  }: IInputPhoneProps & KHookForm) => (
    <KFormGroupUser label={label} name={name}>
      <Controller
        name={name}
        render={({ field }) => (
          <KInputPhone
            {...field}
            {...props}
            value={formatPhoneNumber(field.value)}
            addonBefore="+1"
            placeholder="###-##-####"
            onChange={(e) => field.onChange(formatPhoneNumber(e.target.value))}
          />
        )}
      />
    </KFormGroupUser>
  ),
  InputUnit: (props: IInputWithDropdownProps) => {
    const { setValue, getValues, watch } = useFormContext();
    return (
      <KInputWithDropdownFormField
        {...props}
        setValue={setValue}
        getValues={getValues}
        watch={watch}
      />
    );
  },
  Switch: ({
    label,
    name,
    styleForm,
    title,
    icon,
    ...props
  }: ISwitchForm & SwitchProps) => {
    const { control } = useFormContext();
    const customLabel = (
      <Flex align="center" gap={4}>
        {icon}
        <KLgText700Secondary88>{title}</KLgText700Secondary88>
      </Flex>
    );
    return (
      <FormItemSwitch
        colon={false}
        label={label || customLabel}
        control={control}
        name={name}
        style={styleForm}
      >
        <KSwitch {...props} />
      </FormItemSwitch>
    );
  },
  DatePicker: ({ label, name, ...props }: KDatePickerProps & KHookForm) => (
    <KFormGroupUser label={label} name={name}>
      <Controller
        name={name}
        render={({ field }) => (
          <KDatePicker
            {...props}
            {...field}
            onChange={(e) => {
              field.onChange(
                formatDate(e, props.format || "LOCAL_WITH_OFFSET")
              );
            }}
          />
        )}
      />
    </KFormGroupUser>
  ),
  EndDatePicker: ({ label, name, ...props }: KDatePickerProps & KHookForm) => {
    const { getValues, setValue, watch } = useFormContext();
    const disabledDate = (currentDate: moment.Moment) => {
      const startTime = getValues("startTime");
      if (!startTime) return false;
      const startDate = moment(startTime, EDateTimeFormat.US_DATE);
      const currentDay = moment(currentDate, EDateTimeFormat.US_DATE);
      return currentDay.isBefore(startDate, 'day');
    };

    useEffect(() => {
      const startTime = getValues("startTime");
      const endTime = getValues(name);
      if (startTime && endTime && moment(startTime).isAfter(moment(endTime))) {
        setValue(name, startTime);
      }
    }, [watch("startTime")]);

    return (
      <KFormGroupUser label={label} name={name}>
        <KDatePicker {...props} disabledDate={disabledDate} />
      </KFormGroupUser>
    );
  },
  DateTimePicker: ({
    label,
    name,
    format,
    suffixTime,
    disabled = false,
    ...props
  }: KDatePickerProps & KHookForm) => {
    const { setValue, getValues, watch } = useFormContext();
    const dateTime = useMemo(() => {
      const dateMoment = moment(getValues(name));
      if (!dateMoment.isValid() || !getValues(name)) return;
      return dateMoment.format();
    }, [watch(name)]);

    const handleChangeDate = (value: moment.Moment | null) => {
      const date =
        value?.format(EDateTimeFormat.US_DATE) ||
        moment().format(EDateTimeFormat.US_DATE);
      const time =
        value?.format(EDateTimeFormat.FULL_TIME) ||
        moment().format(EDateTimeFormat.FULL_TIME);
      const formattedDateTime = moment(`${date} ${time}`)
        .utc()
        .format(EDateTimeFormat.UTC);
      setValue(name, formattedDateTime, { shouldDirty: true });
    };

    return (
      <Flex gap={16} justify="space-between" flex={1}>
        <KFormGroupNoControl label={label}>
          <KDatePicker
            value={dateTime}
            allowClear={false}
            onChange={handleChangeDate}
            {...props}
          />
        </KFormGroupNoControl>
        <KFormGroupNoControl
          label={
            <Flex align="center" gap={4}>
              <KMdText>Time</KMdText>
              {suffixTime ? (
                <KMdTextSecondary65>({suffixTime})</KMdTextSecondary65>
              ) : null}
            </Flex>
          }
        >
          <KTimePicker
            allowClear={false}
            value={dateTime}
            onChange={handleChangeDate}
            {...props}
          />
        </KFormGroupNoControl>
      </Flex>
    );
  },
  TimePicker: ({
    label,
    name,
    disabled = false,
    ...props
  }: KDatePickerProps & KHookForm) => {
    <KFormGroupUser label={label} name={name}>
      <KTimePicker {...props} />
    </KFormGroupUser>;
  },
  TextArea: ({
    label,
    name,
    placeholder = "Enter any additional details about this",
    ...props
  }: KTextAreaProps & KHookForm) => (
    <KFormGroupUser label={label} name={name}>
      <KTextarea placeholder={placeholder} {...props} />
    </KFormGroupUser>
  ),
  Select: ({
    label,
    name,
    placeholder = "Select",
    ...props
  }: KSelectProps & KHookForm) => (
    <KFormGroupUser label={label} name={name}>
      <KSelect placeholder={placeholder} {...props} />
    </KFormGroupUser>
  ),
  TextEditor: ({ label, name, ...props }: KTextEditorProps & KHookForm) => {
    const { setValue, watch } = useFormContext();

    const onChangeEditor = useCallback((val: string) => {
      setValue(name, val, { shouldDirty: true });
    }, [setValue, name]);

    return (
      <KFormGroupNoControl label={label}>
        <KTextEditor
          {...props}
          value={watch(name)}
          onChange={onChangeEditor}
        />
      </KFormGroupNoControl>
    );
  },
  AutoComplete: ({
    label,
    name,
    ...props
  }: AutoCompleteProps & KHookForm) => (
    <KFormGroupUser label={label} name={name}>
      <AutoComplete {...props} />
    </KFormGroupUser>
  ),
  OptionsSelector: ({
    label,
    options,
    cardType,
    name: fieldName,
    displayName: fieldDisplay,
    ...props
  }: IOptionsLink) => {
    const { profileId } = useParams();
    const { editableDetail } = useDetailCard();
    const { navigateDetailCard } = useContext(KContext);
    const [defaultOptions, setDefaultOptions] = useState<ICardTagRes[]>();
    const [search, setSearch] = useState("");
    const [openModal, setOpenModal] = useState(false);
    const method = useFormContext();
    const { setValue, getValues, watch } = method;
    const { title } = getFieldDetail(cardType);
    const name = fieldName || getFieldDetail(cardType).name;
    const displayName = fieldDisplay || getFieldDetail(cardType).displayName;

    useEffect(() => {
      return () => setDefaultOptions(undefined);
    }, []);

    useEffect(() => {
      setSearch(getValues(displayName));
    }, [watch(displayName)]);

    const currentValue = useMemo(() => {
      return getValues(name);
    }, [watch(name)]);

    const onOpenAddCard = () => {
      setValue(displayName, getValues(displayName));
      setValue(name, getValues(name));
      setOpenModal(true);
    };

    const filterOptions = useCallback(
      (input: string) => {
        if (!input) return defaultOptions;
        return defaultOptions?.filter((option) =>
          option.title?.toString().toLowerCase().includes(input.toLowerCase())
        );
      },
      [defaultOptions]
    );

    const customOptions = useMemo(
      () => [
        {
          label: (
            <Flex justify="space-between" onClick={onOpenAddCard}>
              Quick Add {title}
              <KButton size="small" title="Add New" ghost />
            </Flex>
          ),
        },
        ...(filterOptions(search)?.map((op) => ({
          value: op.title,
          label: (
            <KCardTypeTag
              title={op.title}
              $cardType={op.cardType || cardType}
            />
          ),
          key: op._id,
        })) || []),
      ],
      [defaultOptions, search]
    );

    const onFocusLinkCard = async () => {
      const path = getInfoCard(cardType).path;
      if (!profileId || !editableDetail || !path || defaultOptions) return;
      try {
        const { data } = await getListAddToCardService(profileId, path);
        setDefaultOptions(data);
      } finally {
      }
    };

    const onSelect = useCallback(
      (value: string, option: any) => {
        setSearch(value);
        setValue(name, option.key, { shouldDirty: true });
      },
      [name, setValue, setSearch]
    );

    const onClear = useCallback(() => {
      setValue(name, null, { shouldDirty: true });
      setValue("multipleLocations", null, { shouldDirty: true })
    }, [name, setValue]);

    const handleGoTo = async () => {
      navigateDetailCard(cardType, getValues(name));
    };

    return (
      <Flex flex={1} gap={8} vertical>
        <Flex gap={16} justify="space-between" align="center">
          <KMdText>{label}</KMdText>
          {!!currentValue && (
            <Flex
              gap={4}
              align="center"
              justify="center"
              onClick={handleGoTo}
              style={{ cursor: "pointer" }}
            >
              <KAnchor>Go to</KAnchor>
              <VectorRightIcon color={Color.PrimaryBase} />
            </Flex>
          )}
        </Flex>
        <AutoComplete
          allowClear
          onClear={onClear}
          onFocus={onFocusLinkCard}
          value={search}
          onSearch={setSearch}
          options={customOptions}
          placeholder={`Search ${title}s`}
          onSelect={onSelect}
          {...props}
        />
        <KModalAddCard
          updateMethods={method}
          cardType={cardType}
          onOpen={setOpenModal}
          open={openModal}
        />
      </Flex>
    );
  },
};
